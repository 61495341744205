import Base from '../../config';

const Project =
  [
    {
      "id": 1,
      "name": "Bhajjanka's Mansion",
      "place": "Raipur",
      "year": 2022,
      "link": "",
      "image_path": Base.b1
    },
    {
      "id": 2,
      "name": "Swarnbhoomi 2",
      "place": "Raipur",
      "year": 2022,
      "link": "Swarnbhoomi_2/15",
      "image_path": Base.b2
    },
    {
      "id": 3,
      "name": "Corporate Clients",
      "place": "Bilaspur",
      "year": 2024,
      "link": "",
      "image_path": Base.b3
    },
  ]

export default Project;
// "link": "Swarnbhoomi_1/18",
// "link": "Corporate_Clients/4"