import I1 from '../images/projects/Banglow1/i1.webp';
import I2 from '../images/projects/Banglow1/i2.webp';
import I3 from '../images/projects/Banglow1/i3.webp';
import I4 from '../images/projects/Banglow1/i4.webp';
import I5 from '../images/projects/Banglow1/i5.webp';
import I6 from '../images/projects/Banglow1/i6.webp';
import I7 from '../images/projects/Banglow1/i7.webp';
import I8 from '../images/projects/Banglow1/i8.webp';
import I9 from '../images/projects/Banglow1/i9.webp';
import I10 from '../images/projects/Banglow1/i10.webp';
import I11 from '../images/projects/Banglow1/i11.webp';
import I12 from '../images/projects/Banglow1/i12.webp';
import I13 from '../images/projects/Banglow1/i13.webp';
import I14 from '../images/projects/Banglow1/i14.webp';
import I15 from '../images/projects/Banglow1/i15.webp';
import I16 from '../images/projects/Banglow1/i16.webp';
import I17 from '../images/projects/Banglow1/i17.webp';
import I18 from '../images/projects/Banglow1/i18.webp';

import M1 from '../images/projects/Banglow2/m1.webp';
import M2 from '../images/projects/Banglow2/m2.webp';
import M3 from '../images/projects/Banglow2/m3.webp';
import M4 from '../images/projects/Banglow2/m4.webp';
import M5 from '../images/projects/Banglow2/m5.webp';
import M6 from '../images/projects/Banglow2/m6.webp';
import M7 from '../images/projects/Banglow2/m7.webp';
import M8 from '../images/projects/Banglow2/m8.webp';
import M9 from '../images/projects/Banglow2/m9.webp';
import M10 from '../images/projects/Banglow2/m10.webp';
import M11 from '../images/projects/Banglow2/m11.webp';
import M12 from '../images/projects/Banglow2/m12.webp';
import M13 from '../images/projects/Banglow2/m13.webp';
import M14 from '../images/projects/Banglow2/m14.webp';
import M15 from '../images/projects/Banglow2/m15.webp';

import C1 from '../images/projects/corp/mark.webp';
import C2 from '../images/projects/corp/brilliant.webp';
import C3 from '../images/projects/corp/radiant.webp';
import C4 from '../images/projects/corp/vardaan.webp';

const Pimg = {
    Swarnbhoomi_1_count: 18,
    Swarnbhoomi_11:I1,Swarnbhoomi_12:I2,Swarnbhoomi_13:I3,Swarnbhoomi_14:I4,Swarnbhoomi_15:I5,Swarnbhoomi_16:I6,Swarnbhoomi_17:I7,Swarnbhoomi_18:I8,Swarnbhoomi_19:I9,Swarnbhoomi_110:I10,Swarnbhoomi_111:I11,Swarnbhoomi_112:I12,Swarnbhoomi_113:I13,Swarnbhoomi_114:I14,Swarnbhoomi_115:I15,Swarnbhoomi_116:I16,Swarnbhoomi_117:I17,Swarnbhoomi_118:I18,
    
    Swarnbhoomi_2_count: 15,
    Swarnbhoomi_21:M1,Swarnbhoomi_22:M2,Swarnbhoomi_23:M3,Swarnbhoomi_24:M4,Swarnbhoomi_25:M5,Swarnbhoomi_26:M6,Swarnbhoomi_27:M7,Swarnbhoomi_28:M8,Swarnbhoomi_29:M9,Swarnbhoomi_210:M10,Swarnbhoomi_211:M11,Swarnbhoomi_212:M12,Swarnbhoomi_213:M13,Swarnbhoomi_214:M14,Swarnbhoomi_215:M15,
    
    Corporate_Clients_count: 4,
    Corporate_Clients1:C1,
    Corporate_Clients2:C2,
    Corporate_Clients3:C3,
    Corporate_Clients4:C4,
  };
  
  export default Pimg;