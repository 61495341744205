import React, { useContext, useEffect } from 'react';
import Context from '../utils/context/Context';
import { useParams } from 'react-router-dom';
import Cursor from '../components/mouse/Cursor';
import { Row, Col, Container } from 'react-grid-system';
import Pimg from '../data/Pimg';
import $ from 'jquery';
import "../css/project.css"
import plan1 from "../images/projects/plan1.png"
import plan2 from "../images/projects/plan2.png"
import plan3 from "../images/projects/plan3.png"
import Base from '../../config';




const Project = () => {
    const { preloader, setpreloader } = useContext(Context);
    const { project, imgCount } = useParams();


    const imgArray = Array.from({ length: imgCount }, (_, index) => index + 1);

    const title = project.replace(/_/g, ' ');

    const load = () => {
        setTimeout(() => {
            $('.projectcard-left').css({ left: "0vw" });
            $('.projectcard-right').css({ right: "0vw" });
        }, 100);
    }

    useEffect(() => {
        if (preloader === true) {
            setTimeout(() => {
                setpreloader(false);
                load();
            }, 2000);
        } else {
            setpreloader(false);
            load();
        }
    }, []);
    return (
        <div>



            <div className="container gaurang w-100">
                <img src={Base.main} alt="" srcset="" className='w-100' />
            </div>
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="bhoomi">
                            <h1 className='main-head'>Swarnbhoomi 2</h1>

                        </div>
                    </Col>
                </Row>
            </Container>  

            <Container>
                <Row>

                    <Col md={7}>
                        <p className="dummy-text ">
                        Here, the first floor has a total area of 6,219 square feet. It is split across three blocks: A, B and C. Block A has an area of 2,030 square feet and a covered balcony of 308 square feet. Block B comprises a built-up area of 1,461 square feet and a covered balcony area of 443 square feet whereas Block C features a built-up area of 1,703 square feet and a covered balcony area of 274 square feet.
                        </p>
                    </Col>
                </Row>
            </Container>



            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="bhoomi">

                            <h1 className='main-head'>Technical description</h1>
                        </div>
                    </Col>
                </Row>
            </Container>


            <Container>
                <Row>

                    <Col md={7}>
                        <p className="dummy-text head-para">
                            Our expertise ensures each project meets the highest standards of quality, efficiency, and regulatory compliance.
                        </p>
                    </Col>
                </Row>
            </Container>





            <Container>
                <Row>
                    <Col sm={6}>

                        <div className="flex justify-between">
                            <div>
                                <h4 className='main-head' style={{ fontSize: "21px" }}>Area</h4>
                                <p style={{ fontSize: "16px" }} className='head-para'>140ft × 100ft</p>
                            </div>
                            <div>
                                <h4 className='main-head' style={{ fontSize: "21px" }}>Architect</h4>
                                <p style={{ fontSize: "16px" }} className='head-para'>Amit Agrawal & Associates</p>
                            </div>
                            <div>
                                <h4 className='main-head' style={{ fontSize: "21px" }}>Year</h4>
                                <p style={{ fontSize: "16px" }} className='head-para'>2021</p>
                            </div>
                        </div>
                    </Col>

                </Row>
            </Container>


            <div className="details">
                <Container>
                    <h1 className='main-head'>Details</h1>

                </Container>
                <Container className="desc ">
                    <ul className='head-para'>
                        <li >Neoclassical Architecture and balanced design</li>
                        <li>Designed for Client and his two sons who have their own family in such <br />a way that they live together but have their own privacy too. Divided into <br /> 3 parts with 3 entries with the head of the family living in middle part.</li>
                        <li>Elevation on all four sides since exposed to roads</li>
                        <li>Made during Covid period which was a hindrance</li>
                        <li>Designed based on Vastu Shastra</li>
                        <li>Site dimensions– 140ft × 100ft</li>
                    </ul>

                </Container>
            </div>




            <div>
                <Container>
                    <Row justify='start' >

                        <Col sm={4} className='custom'>
                            <div className="container-hover">
                                <img src={plan1} alt="" srcset="" />
                            </div>
                        </Col>
                        <Col sm={4} className='custom'>
                            <div className="container-hover" >

                                <img src={plan2} alt="" srcset="" />
                            </div>
                        </Col>
                        <Col sm={4} className='custom'>
                            <div className="container-hover">

                                <img src={plan3} alt="" srcset="" />
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

            <Container>
            <div className="container">        
                <div className="heading">
                    <Container>
                       <Row>

                        <h1 className='main-head'>Images</h1>
                       </Row>
                    </Container>
                </div>
            </div>
            </Container>

            


            <Cursor />
            <Container>    
            <Row justify='center'>  
                <Col sm={6} lg={12}>
                    <Row justify='start'>
                        {imgArray.map((index) => (
                            <Col md={6} key={index}>
                                <img className={index % 2 === 0 ? "w-100 imgcard projectcard-right" : "w-100 imgcard projectcard-left"} src={Pimg[`${project}${index}`]} alt='banglo' />
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
            </Container>  


        </div>
    );
}
export default Project
